import React, { useEffect, useState } from "react";
import styles from "./app.scss";
import { message, Alert } from "antd";
import Head from "./components/head/head";
import { get, post } from "./request";
import Router from "./router";
import moment from "moment";
import { loginCodeKey, cipherKey } from "./constant";
import { useLocation, useNavigate } from "react-router-dom";
import { decrypt, getSearch } from "./utils";

export default function App() {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [loginOpen, setLoginOpen] = useState(false);
  const location = useLocation();
  const [isShow, setIsShow] = useState(false); // 是否可以交互，是否展示提示

  const viewAt = moment().valueOf();
  const gapSecs = 10;
  let viewId = 0;

  const pageView = () => {
    post({
      url: "/fa/view",
      data: {
        viewAt,
        time: gapSecs,
        id: viewId,
        uid: window.user?.id || 0,
      },
      onSuccess: (view) => {
        viewId = view.id;
      },
      onFail: () => {},
    });
  };

  useEffect(() => {
    get({
      url: "/user/info",
      onSuccess: (data) => {
        setUser(data);
      },
      onFail: () => {
        setUser(null);
        loginByCode();
      },
    });
    insertPaypal();

    const handler = setInterval(pageView, gapSecs * 1000);
    return () => {
      clearInterval(handler);
    };
  }, []);

  // useEffect(() => {
  //   if (location.search) {
  //     if (getSearch(location.search).cipher) {
  //       const cipher = getSearch(location.search).cipher;
  //       // 判断cipher是否符合条件
  //       let flag = JSON.parse(decrypt(decodeURIComponent(cipher)));
  //       setIsShow(!flag);
  //       flag && localStorage.setItem(cipherKey, cipher);
  //     } else {
  //       localStorage.getItem(cipherKey)
  //         ? navigate(
  //             `${location.pathname}?cipher=${localStorage.getItem(cipherKey)}`
  //           )
  //         : setIsShow(true);
  //     }
  //   } else {
  //     localStorage.getItem(cipherKey)
  //       ? navigate(
  //           `${location.pathname}?cipher=${localStorage.getItem(cipherKey)}`
  //         )
  //       : setIsShow(true);
  //   }
  // }, [location]);

  const loginByCode = () => {
    let codeInfo = localStorage.getItem(loginCodeKey);
    post({
      url: "/login/code",
      data: {
        code: codeInfo || null,
      },
      onSuccess: (data) => {
        const { user } = data;
        setUser(user);
        window.user = user;
        localStorage.setItem(loginCodeKey, user.code);
      },
    });
  };

  const insertPaypal = () => {
    get({
      url: "/paypal/client_id",
      onSuccess: (clientId) => {
        const script = document.createElement("script");
        script.setAttribute(
          "src",
          `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=USD`
        );
        document.body.appendChild(script);
      },
    });
  };

  return (
    <React.Fragment>
      <Head user={user} />
      <div className={styles.main}>
        <Router user={user} setLoginOpen={setLoginOpen} />
      </div>
      {/* {isShow && (
        <div className={styles.alert}>
          <Alert
            className={styles.tipTxt}
            message="Access Denied"
            type="warning"
            banner
          />
        </div>
      )} */}
    </React.Fragment>
  );
}
